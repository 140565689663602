import React from 'react'

import leftImgPng from "images/economy.png";
import rightBottomImgPng from "images/Amazon.png";
export default function SectionDispatchingEconomy() {
  return (
    <>
    {/* <div className='opacity-0' style={{marginTop: "calc(7rem * calc(1 - var(--tw-space-y-reverse)))"}}>Divider</div> */}
    <div className="pb-14 relative">
        <div className="absolute bg-opacity-40 bg-primary-100 dark:bg-neutral-800 dark:bg-opacity-40 h-full md:bottom-0  top-auto w-screen z-0"></div>
        <div className="container relative">
            <h1 className="text-3xl text-center py-16">Dispatching Economy <span className='font-bold text-primary-6000'>Pallets to Amazon</span></h1>
            <div className="grid md:grid-cols-2 gap-6">
                
                <div className="space-y-8">
                    
                    <div className="flex items-start space-x-4">
                        <span className="text-blue-500 text-2xl">
                            <svg className="h-8 w-8"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="9 18 15 12 9 6" /></svg>
                        </span>
                        <div>
                            <h2 className="text-xl font-semibold">How It Works for You</h2>
                            <p className="flex items-center mt-5 text-neutral-500 dark:text-neutral-400">
                                
                                Using a pallet delivery service can streamline your shipping process, reduce costs, and improve the safety of your items during transit. Companies can benefit from the efficiency and reliability of palletized shipping, ensuring that goods are delivered to perfection.
                            </p>
                        </div>
                    </div>

                    
                    <div className="flex items-start space-x-4">
                        <span className="text-blue-500 text-2xl">
                            <svg className="h-8 w-8"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="9 18 15 12 9 6" /></svg>
                        </span>
                        <div>
                            <h2 className="text-xl font-semibold">For Companies Seeking Perfection in Delivery</h2>
                            <p className="flex items-center mt-5 text-neutral-500 dark:text-neutral-400">
                                
                                For businesses, pallet delivery services offer a reliable and cost-effective solution for shipping large quantities of goods. These services ensure that items are securely packaged and handled with care, reducing the risk of damage and ensuring timely delivery.
                            </p>
                        </div>
                    </div>

                    
                    <div className="flex items-start space-x-4">
                        <span className="text-blue-500 text-2xl">
                            <svg className="h-8 w-8"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="9 18 15 12 9 6" /></svg>
                        </span>
                        <div>
                            <h2 className="text-xl font-semibold">Pallet Dimensions: What You Need to Know</h2>
                            <p className="flex items-center mt-5 text-neutral-500 dark:text-neutral-400">
                                
                                We offer a range of pallet sizes, including Mini, Quarter, Half, Full, and Oversize pallets. Each size accommodates different types and quantities of goods, ensuring flexibility for your shipping needs.
                            </p>
                        </div>
                    </div>

                    
                    <div className="flex items-start space-x-4">
                        <span className="text-blue-500 text-2xl">
                            <svg className="h-8 w-8"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="9 18 15 12 9 6" /></svg>
                        </span>
                        <div>
                            <h2 className="text-xl font-semibold">Global Pallet Delivery Solutions</h2>
                            <p className="flex items-center mt-5 text-neutral-500 dark:text-neutral-400">
                                
                                International pallet delivery services offer a convenient way to ship goods across borders. These services can handle various pallet sizes and provide options for express or economy shipping, depending on your needs. PalletBooking offers reliable international pallet delivery, ensuring your goods reach their destination safely and efficiently.
                            </p>
                        </div>
                    </div>
                </div>

                
                <div className="space-y-4 relative">
                <div className="absolute border-4 border-dashed bottom-[80px] left-0 mx-auto right-0 rounded-3xl top-28 w-2/3 z-0"></div>
                    <img className="rounded-lg absolute object-cover" src={leftImgPng} alt="Warehouse workers"/>
                    <img className="rounded-lg absolute bottom-0 right-0 object-cover" src={rightBottomImgPng} alt="Package scanning"/>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
