import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FetchSessionData, usePalletSubmit, useTrackingSubmit } from 'hooks/Globle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import SectionHero from "components/SectionHero/SectionHero";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionDeliveryServices from "components/SectionDeliveryServices/SectionDeliveryServices";
import SectionDispatchingEconomy from "components/SectionDispatchingEconomy/SectionDispatchingEconomy";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionVideos from "components/SectionVideos/SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionFaqs from "components/SectionFaqs/SectionFaqs";
import Cookies from "js-cookie";
import NcImage from "shared/NcImage/NcImage";
import rightImgPng from "images/pallet-image.png";


const PageHome: React.FC = () => {
  
  const userSession = Cookies.get('userSession');
  const { HandleSubmit } = usePalletSubmit();
  const { TrackingSubmit } = useTrackingSubmit();
  
  const toTop = () => {
    window.scrollTo({top: 0,behavior: 'smooth'})
  }
  
  useEffect(() => {
    if(userSession == null){
      FetchSessionData();
    }
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <ToastContainer />
      <Helmet>
        <title>Home | PalletBooking</title>

        <meta property="og:title" content="Pallet Booking Made Simple" />
        <meta property="og:description" content="Experience hassle-free and cost-effective pallet delivery with Pallet Booking. Serving the UK, we offer same-day collection and real-time tracking for complete peace of mind." />
        <meta property="og:image" content="/images/logo-site.png" />
        <meta property="og:url" content="/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="PalletBooking" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Pallet Booking Made Simple" />
        <meta name="twitter:description" content="Get fast and affordable pallet delivery across the UK. Book before 11:00 AM for same-day collection, and enjoy seamless tracking throughout your pallet's journey." />
        <meta name="twitter:image" content="/images/logo-site.png" />

      </Helmet>
      
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="relative space-y-24 lg:space-y-28 ">
        {/* SECTION HERO */}
        <div className="container">
        <SectionHero handleSubmit={HandleSubmit} trackingSubmit={TrackingSubmit} className="pt-10 lg:pt-16 lg:pb-16" />
        </div>
        <div className="container">
        <div className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center py-0`} data-nc-id="SectionBecomeAnAuthor">
            <div className="-bottom-4 -right-4 -top-4 absolute bg-opacity-40 bg-primary-100 dark:bg-neutral-800 dark:bg-opacity-40 md:bottom-0 md:right-0 md:top-0 rounded-3xl sm:rounded-[50px] w-2/3 z-0"></div>
            <div className="flex-grow relative p-8 z-10">
                <NcImage className="border-4 border-white dark:border-neutral-900 sm:border-[10px] w-full" src={rightImgPng} />
            </div>
            <div className="flex-shrink-0 lg:mb-0 lg:w-3/5 mb-16 p-8 relative z-10">
                <h2 className="font-semibold text-3xl sm:text-4xl">
                Welcome to Pallet Booking
                </h2>
                <p className={`block mt-5 font-semibold text-black-500 dark:text-black-400`}>
                Discover the convenience and affordability of pallet delivery with Pallet Booking. Serving the entire UK, we offer same-day collection for bookings made before 11:00 AM. With our advanced tracking system, you can monitor your pallet's journey every step of the way, ensuring complete peace of mind
                </p>

                <p className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                We cater to various pallet sizes, including Mini, Quarter, Half, Full, and Oversize, tailored to fit your needs. Our flexible delivery options include urgent and weekend deliveries, as well as customer choice time slots. Proudly serving the UK Mainland, Highlands, and Islands, we stand out for our exceptional customer service and reliability. For the lowest prices and a seamless pallet delivery experience, look no further than Pallet Booking. Join our satisfied customers and enjoy a budget-friendly, efficient service today!
                </p>

                <button onClick={toTop} type="button" className="nc-Button relative h-auto inline-flex items-center justify-center rounded-2xl transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 mt-5">
                  <a href="https://uk.trustpilot.com/review/palletbooking.co.uk?utm_medium=trustbox&utm_source=TrustBoxReviewCollector">
                    <div className="btn-default-dark text-uppercase flex flex-col">
                      <span className="flex font-bold">
                      <svg className="h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"/>
                      </svg>

                      REVIEWS.io
                      </span>
                      <span className="subtext font-light">Trusted Site</span>
                    </div>
                  </a>  
                </button>
            </div>
        </div>
        </div>

        {/* SECTION2 */}
        <div className="container">
        <SectionDeliveryServices />
        </div>
        
        <SectionDispatchingEconomy />
        
        
        {/* SECTION2 */}
        {/* <div className="container">
        <SectionOurFeatures />
        </div> */}

        {/* SECTION 3*/}
        {/* <div className="container">
        <SectionHowItWork />
        </div> */}

        {/* SECTION 4 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}

        {/* SECTION 5*/}
        {/* <div className="container">
        <SectionSubscribe2 />
        </div> */}
        {/* SECTION 6*/}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 7*/}
        <div className="container">
        <SectionVideos />
        </div>    
        {/* SECTION 8*/}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
        
        <SectionFaqs />
        
      </div>
    </div>
  );
}

export default PageHome;
