import Logo from "shared/Logo/Logo";
// import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Quick Links",
    menus: [
      { href: "/", label: " Home" },
      { href: "why-us", label: "Why Us" },
      { href: "contact-us", label: "Contact Us" },
      { href: "terms-of-use", label: "Terms & Condition" },
      { href: "terms-of-use#Privacy", label: "Privacy" },
    ],
  },
  {
    id: "1",
    title: "CONTACT US",
    menus: [
      { label: `Palletbooking Ltd, Unit D, Knaves Beech Industrial Estate, <br /> Knaves Beech Way, Loudwater, <br /> High Wycombe HP10 9QY <br /> United Kingdom`,
        icon:`<svg class="h-4 w-4 text-dark-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
        </svg>` 
      },
      { href: "tel:+4401753525959", label: "+44 (0) 1753525959", icon:`<svg class="h-4 w-4 text-dark-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>` 
      },
      { href: "mailto:sales@palletbooking.co.uk", label: "sales@palletbooking.co.uk", icon:`<svg class="h-4 w-4 text-dark-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="3 9 12 15 21 9 12 3 3 9" />  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />  <line x1="3" y1="19" x2="9" y2="13" />  <line x1="15" y1="13" x2="21" y2="19" /></svg>` 
      },
    ],
  },
  
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index} className="flex justify-start items-start">
              { (item.icon) ? (<div dangerouslySetInnerHTML={{ __html: item.icon }}></div>) : ('') }
              <NavLink
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white ml-1"
                to={item.href || ""}
              > {<div dangerouslySetInnerHTML={{ __html: item.label }}></div>}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative pt-16 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          {/* <div className="col-span-2 flex items-center md:col-span-2">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div> */}
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="bg-neutral-900 mt-12 py-2 text-white">
        <div className="container text-center">
        <p className="mb-0 text-[11px]">© 2024 Palletbooking. All rights reserved. Website Design by <a className="" href="https://bookingwhizz.com/">BookingWhizz</a></p>
      </div>
      </div>
    </div>
  );
};

export default Footer;
